<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h1 class="h3 mb-4">
      重置密碼
    </h1>
    <div class="col-12 col-md-6 col-lg-5">
      <div
        v-for="field in fieldList"
        :key="field"
      >
        <InputPassword
          :id="field"
          v-model:input="state[field]"
          class="mb-4"
          :type="field"
          :required="true"
          :label="labelMap[field].label"
          :placeholder="labelMap[field].placeholder"
          :err-message="v$[field]?.$errors[0]?.$message || ''"
          @focus="v$[field].$touch"
          @enter="updateResetPasswordRequest"
        />
      </div>
      <div class="text-center pt-2">
        <button
          class="btn btn-primary text-white rounded-pill letter-spacing-s m-1"
          @click="updateResetPasswordRequest"
        >
          確認變更
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { requiredHelper, passwordHelper, helpers, sameAs } from '@/hooks/useVuelidate.js'

import { ref, toRef, inject, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import InputPassword from '@/components/InputPassword.vue'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  name: 'ResetPassword',
  components: {
    InputPassword
  },
  props: {
    clientID: {
      type: String,
      default: ''
    },
    token: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { dispatch } = useStore()
    const { push } = useRouter()
    const { getErrorMessage, getSuccessMessage } = useErrorMessage()

    // const clientID = toRef(props, 'clientID')
    const token = toRef(props, 'token')

    const password = ref('')
    const checkPassword = ref('')

    const setAlert = inject('setAlert')

    const fieldList = ['password', 'checkPassword']

    const labelMap = {
      password: {
        label: '輸入密碼',
        placeholder: '請輸入密碼'
      },
      checkPassword: {
        label: '再次輸入密碼',
        placeholder: '請再次輸入密碼'
      }
    }

    const state = reactive({
      password,
      checkPassword
    })

    const rules = {
      password: { requiredHelper, passwordHelper },
      checkPassword: {
        requiredHelper,
        passwordHelper,
        sameAsRef: helpers.withMessage('請確認兩次密碼是否一致', sameAs(password))
      }
    }
    const v$ = useVuelidate(rules, state)

    const updateResetPasswordRequest = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        const payload = { token: token.value, newPw: state.password }

        try {
          const response = await dispatch('validate/updateResetPasswordRequest', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, getSuccessMessage(response))
            window.setTimeout(() => { push('/login') }, 2000)
          }

          return response
        } catch (error) {
          setAlert(true, false, `更新失敗: ${error}`)
        }
      }
    }

    return {
      v$,
      state,
      password,
      checkPassword,
      fieldList,
      labelMap,
      updateResetPasswordRequest
    }
  }
}
</script>
<style lang="scss" module>

.icon {
  height: 1.2rem;
  width: 1.2rem;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}

</style>
